import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  image as ImagePropType,
  video as VideoPropType,
} from 'lib/CustomPropTypes';

import Caption from 'components/Caption';

import styles from './styles.module.scss';

export function MediaCaption({
  className,
  primaryMedia = null,
}) {
  if (!primaryMedia) return null;

  return (
    <div className={classNames(styles.mediaCaption, className)} data-testid="media-caption">
      <Caption image={primaryMedia?.image} id="duet-main-media-caption" />
    </div>
  );
}

MediaCaption.propTypes = {
  className: PropTypes.string,
  primaryMedia: PropTypes.oneOfType([ImagePropType, VideoPropType]),
};
