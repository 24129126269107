import React from 'react';
import PropTypes from 'prop-types';

const instagramLogo = require('assets/images/instagram-logo-color.png');

const Instagram = ({
  closeBannerDelayed,
  title = 'Don\'t miss a beat, like us on social',
  description = 'Trending stories, celebrity news and all the best of TODAY.',
}) => (
  <div>
    <div className="mobile-banner__header founders-cond f6">
      {title}
    </div>
    <div className="mobile-banner__body publico-txt f2 mt2 mb5">
      {description}
    </div>
    <button
      type="button"
      // eslint-disable-next-line max-len
      className="mobile-banner__social-button founders-cond items-center content-center bg-white tc lh-none ttu pv2"
      id="mobile-banner-instagram"
      onClick={closeBannerDelayed}
    >
      <a
        href="https://instagram.com/todayshow"
        target="_blank"
        rel="noopener noreferrer"
        className="mh4 dif items-center content-center"
      >
        <img
          src={instagramLogo}
          className="mobile-banner__social-button--logo mt1"
          alt="instagram logo color"
        />
        <div className="ml2 f4 lh-copy">INSTAGRAM</div>
      </a>
    </button>
  </div>
);

Instagram.propTypes = {
  closeBannerDelayed: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};


export default Instagram;
