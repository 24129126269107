import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addClassesToElement } from 'lib/addClassesToElement';

import styles from './styles.module.scss';

export function DuetLayout({
  mainMedia = null,
  headingContent = null,
  mediaCaption = null,
  mainContent = null,
  className = undefined,
  hasMainMedia = false,
}) {
  return (
    <div className={classNames(styles.duetLayout, className, {
      [styles.hasNoMainMedia]: !hasMainMedia,
    })}
    >
      <div className={styles.duetHeroContainer}>
        <header className={styles.duetHero} data-test="article-hero">
          {addClassesToElement(mainMedia, styles.gridAreaMainMedia)}
          {addClassesToElement(mediaCaption, styles.gridAreaMainCaption)}
          {addClassesToElement(headingContent, styles.gridAreaHeading)}
        </header>
      </div>
      {addClassesToElement(mainContent, styles.gridAreaMain)}
    </div>
  );
}

DuetLayout.propTypes = {
  mainMedia: PropTypes.element,
  headingContent: PropTypes.element,
  mediaCaption: PropTypes.element,
  mainContent: PropTypes.element,
  className: PropTypes.string,
  hasMainMedia: PropTypes.bool,
};
