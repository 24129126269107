import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({
  title = 'Don\'t miss a beat, like us on Facebook',
  description = 'Trending stories, celebrity news and all the best of TODAY.',
}) => (
  <div>
    <div className="mobile-banner__header founders-cond f6">
      {title}
    </div>
    <div className="mobile-banner__body publico-txt f2 mt2 mb5">
      {description}
    </div>
    <div id="mobile-banner-fb-like">
      <iframe
        loading="lazy"
        title="mobile-banner-fb-like"
        // eslint-disable-next-line max-len
        src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Ftodayshow&width=450&layout=standard&action=like&share=false&height=35&show_faces=true&appId"
        width="450"
        height="35"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        show-faces="true"
      />
    </div>
  </div>
);

Facebook.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};


export default Facebook;
